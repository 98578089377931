define('m03/components/mutte-r', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Extrusion = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'mutte-r',
    attributeBindings: [],
    classNames: ['testClassNameXXX'],
    bboxsize: "-1,-1,-1",
    ccw: "true",

    translation: (function () {
      var self = this;
      var name = self.get('name');
      var x3d = self.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var x = 0,
          y = 0,
          z = 0;

      x = item.get('punkt').objectAt(0).get('x') / 10;
      y = item.get('punkt').objectAt(0).get('y') / 10;
      z = item.get('punkt').objectAt(0).get('z') / 10 - Number(item.get('mutter').objectAt(0).get('h')) / 10;

      var values = x + " " + y + " " + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.uScheibe'),

    rotation: (function () {
      var self = this;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var values = '';
      var drehwinckelYAchse = 0,
          drehwinckelZAchse = 0;

      var x = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('x');
      var y = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('y');
      var z = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('z');

      drehwinckelYAchse = self.getWinkel({ x: x, y: 0, z: z }, { x: 0, y: 0, z: 1 });
      drehwinckelZAchse = self.getWinkel({ x: x, y: y, z: z }, { x: 0, y: 1, z: 0 });

      var xWertRotationsachse = Math.cos(drehwinckelYAchse);
      var zWertRotationsachse = -Math.sin(drehwinckelYAchse);

      values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.uScheibe'),

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    crosssection: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = false;
      var x, y, z;

      var durchmesser = item.get('mutter').objectAt(0).get('sw') / 10;
      x = durchmesser / 2;

      var x2 = durchmesser * Math.cos(1.0472) / 2;
      z = durchmesser * Math.sin(1.0472) / 2;

      values = [-x, 0, -x2, z, x2, z, x, 0, x2, -z, -x2, -z, -x, 0].toString();

      // var x2=schraubenDurchmesser*Math.cos(0.5236)/2;
      // var x3=schraubenDurchmesser*Math.cos(1.0472)/2;
      //
      // var z2 = schraubenDurchmesser*Math.sin(0.5236)/2;
      // var z3 = schraubenDurchmesser*Math.sin(1.0472)/2;
      //
      // z = x*0.5-0.05;
      // values = [-x, 0, -x2, z2, -x3, z3, 0, x, x3, z3, x2, z2, x, 0, x2, -z2, x3, -z3, 0, -x, -x3, -z3, -x2, -z2, -x, 0 ].toString();

      return values;
    }).property('model.firstObject.transformHelper'),

    //orientation beschreibt die Richtung in der die Extrusion erfolgt
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Richtung für jede Stufe anzugeben.
    orientation: (function () {
      var name = this.get('name');

      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = '0 1 0 0, 0 1 0 0';

      return values;
    }).property('model.firstObject.transformHelper'),

    //scale beschreibt die Skalierung der Grundfläche während eines Extrusionschritts
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Skalierung für jeden Schritt/Stufe anzugeben.
    scale: (function () {
      var name = this.get('name');

      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = "1 1, 1 1";

      // var durchmesser = item.get('mutter').objectAt(0).get('sw')/4;
      // values = durchmesser +' '+ durchmesser +', '+ durchmesser +' '+ durchmesser;

      return values;
    }).property('model.firstObject.transformHelper'),

    //spine beschreibt die Länge der Extrusion in y-Richtung
    //Die Extrusion kann in mehreren Schritten/Stufen erfolgen
    spine: (function () {
      var name = this.get('name');

      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = '0 0 0';

      var x = 0;
      var y = Number(item.get('mutter').objectAt(0).get('h')) / 10;
      var z = 0;

      values = values + ' ' + x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper'),

    farbe: (function () {
      // var name = this.get('name');
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Extrusion;

});