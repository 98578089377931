define('m03/controllers/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    itw: false, //don't change this!
    swg: false, //don't change this!

    schraubenliste: [],
    calcid: "",
    tooltiptext: "",
    loading: true,
    showinvalidscrews: false,
    validScrewSelected: false,
    jstreeStateOptionHash: {
      'key': ''
    },
    plugins: 'state',

    selectedNodes: "",

    zeigeFilterergebnis: false,

    init: function init() {

      this._super();

      var lasteinwirkung = this.controllerFor('lasteinwirkung');
      var bauteile = this.controllerFor('bauteile');
      if (!lasteinwirkung.get('lasteinwirkungInvalid') || !bauteile.get('bauteileInvalid')) {
        this.transitionToRoute('projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }
    },

    getGefilterteListe: function getGefilterteListe(schraubenliste) {

      // var liste = jQuery.parseJSON(data.Item2);

      var filterbyjquery = $.grep(schraubenliste, function (element, i) {

        return element.VM_Okay === "True";
      });

      // console.log(filterbyjquery);

      var durchmesser = [];

      filterbyjquery.forEach(function (item) {

        var ll = durchmesser.filter(function (elem) {
          return elem.id === item.d_1;
        });

        if (typeof ll.findBy('l_s', item.l_s) === 'undefined') {
          durchmesser.push({ id: item.d_1, l_s: item.l_s, count: 1 });
        } else {

          var e = durchmesser.filter(function (elem) {
            return elem.id === item.d_1 && elem.l_s === item.l_s;
          });

          e[0].count = e[0].count + 1;
        }
      });

      // console.log(durchmesser);

      var lSortierteListe = filterbyjquery.filter(function (item) {

        var abmessung = durchmesser.filter(function (elem) {
          return elem.id === item.d_1 && elem.l_s === item.l_s;
        });

        // if(abmessung[0].count < 3){
        //   console.log(item);
        // }

        return abmessung[0].count > 2;
      });

      return lSortierteListe;
    },

    watchZeigeFilterergebnis: (function () {

      var self = this;

      var schraubenliste;
      var core;

      if (self.get('zeigeFilterergebnis') === true) {

        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        schraubenliste = self.controllerFor('lasteinwirkung').get('verbindungsmittelliste');

        var gefilterteListe = self.getGefilterteListe(schraubenliste);

        console.log("gefilterteListe");
        console.log(gefilterteListe);

        core = treetools.buildTree(gefilterteListe, false);
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        schraubenliste = self.controllerFor('lasteinwirkung').get('verbindungsmittelliste');
        core = treetools.buildTree(schraubenliste, true);
        self.set('schraubenliste', core);
      }
    }).observes('zeigeFilterergebnis'),

    watchInvalidScrewsCombo: (function () {

      var self = this;

      if (self.showinvalidscrews) {

        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        if (self.get('itw')) {
          var core = treetoolsitw.buildTree(schraubenliste, true);
        } else {
          var core = treetools.buildTree(schraubenliste, true);
        }
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        if (self.get('itw')) {
          var core = treetoolsitw.buildTree(schraubenliste, false);
        } else {
          var core = treetools.buildTree(schraubenliste, false);
        }
        self.set('schraubenliste', core);
      }
    }).observes('showinvalidscrews'),

    watchSelectedScrew: (function () {

      var self = this;
      var application = self.controllerFor('application');
      var downloadcenter = self.controllerFor('downloadcenter');

      var ergebnis = self.controllerFor('ergebnis');

      // console.log(self.jstreeStateOptionHash);

      if (self.selectedNodes.length > 0) {

        if (self.selectedNodes[0].data) {

          if (self.selectedNodes[0].data.VM_Okay === "True") {

            application.set('verbindungsmittelInvalid', false);
            application.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
            application.set('schraubenCAD', self.selectedNodes[0].data.CAD);
            self.set('validScrewSelected', true);

            downloadcenter.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
            downloadcenter.set('schraubenCAD', self.selectedNodes[0].data.CAD);
            downloadcenter.set('validScrewSelected', true);

            ergebnis.set('pdfErstellt', false);
            ergebnis.set('dxfErstellt', false);

            this.ergebnisgrafik();
          } else {

            application.set('verbindungsmittelInvalid', true);
            self.set('validScrewSelected', false);
            downloadcenter.set('validScrewSelected', false);

            var application = this.controllerFor('application');
            var x3d = application.get('model').x3ddefault.objectAt(0);
            var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

            x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
            x3d.set('ergebnisGeladen', false);
            x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
            x3d.set('transformHelper', !x3d.get('transformHelper'));
          }
        } else {
          console.log("not true!");
          application.set('verbindungsmittelInvalid', true);
          self.set('validScrewSelected', false);
          downloadcenter.set('validScrewSelected', false);

          var application = this.controllerFor('application');
          var x3d = application.get('model').x3ddefault.objectAt(0);
          var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

          x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
          x3d.set('ergebnisGeladen', false);
          x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
          x3d.set('transformHelper', !x3d.get('transformHelper'));
        }
      }
    }).observes('selectedNodes'),

    ergebnisgrafik: function ergebnisgrafik() {

      var self = this;

      var selectedScrew = self.selectedNodes[0].data.ArtNr;
      var cidGrafik = self.calcid;

      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var store = application.get('store');
      var arrayHelper = [];

      // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen
      application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.get('typ') === 'verbindungsmittel' || item.get('typ') === 'masskette' || item.get('typ') === 'platte' || item.get('typ') === 'uScheibe' || item.get('typ') === 'mutter') {
          arrayHelper.pushObject(item);
        }
      });
      arrayHelper.forEach(function (item) {
        application.get('model').x3ddefault.objectAt(0).get('bauteile').removeObject(item);
      });

      var server = application.get('server');
      var pfad = application.get('pfad');

      var x3d = application.get('model').x3ddefault.objectAt(0);

      console.log('selectedScrew: ' + selectedScrew);

      $.ajax({
        type: "POST",
        url: server + "getGrafik/",
        data: {
          cid: cidGrafik,
          artnr: selectedScrew
        }
      }).done(function (data) {

        console.log('Grafikdaten der ausgewählten Schraube');
        console.log(data);

        var dataObject = JSON.parse(data);

        // console.log('bauteile');

        dataObject.bauteile.forEach(function (item) {

          // console.log(item.id);

          var newID = item.id;
          if (item.typ === "verbindungsmittel") {
            newID = "schraubeStandard1";
          } else if (item.typ === "platte") {
            if (item.id.substring(0, 13) === 'seitenbauteil' && x3d.get('sFolgeHolzHolz') === false && x3d.get('zweiSchnittig') === false) {
              newID = "metall";
            } else if (item.id.substring(0, 13) === 'mittelbauteil' && x3d.get('sFolgeHolzHolz') === false && x3d.get('zweiSchnittig') === true && x3d.get('zweiSchaerflaechen') === true) {
              newID = "metall";
            } else if (item.id.substring(0, 16) === 'verstaerkunggurt' || item.id.substring(0, 15) === 'zwischenschicht') {
              newID = "verstaerkungBlass1";
            } else if (item.id.substring(0, 21) === 'verstaerkungdiagonale') {
              newID = "verstaerkungWerner1";
            } else {
              newID = "holz";
            }
          }

          // console.log(item.typ);

          if (item.typ === "verbindungsmittel") {

            // console.log('in Verbindungsmittel');

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: [newID],
              diffusecolors: [newID],
              screwProperties: [item.verbindungsmittel],
              richtungsvektor: [item.richtungsvektor],
              lagewinkel: [item.lagewinkel]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          } else if (item.typ === "platte") {

            // console.log('in Platte');

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: [newID],
              diffusecolors: [newID],
              richtungsvektor: [item.richtungsvektor],
              orthovektor: [item.orthovektor],
              platte: [item.id]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          } else if (item.typ === "masskette") {

            // console.log('in Masskette');

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: [newID],
              diffusecolors: [newID],
              richtungsvektor: [item.richtungsvektor],
              // orthovektor: [item.orthovektor],
              masskette: [item.id],
              ansichtsvektoren: [item.offsetVektorAnsichtXY, item.offsetVektorAnsichtXZ, item.offsetVektorAnsichtYZ]

            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          } else if (item.typ === "uScheibe") {

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: ["uScheibeStandard1"],
              diffusecolors: ["uScheibeStandard1"],
              richtungsvektor: [item.richtungsvektor],
              uscheibe: [item.uScheibe]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          } else if (item.typ === "mutter") {

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: ["null"],
              rotations: ["null"],
              appearances: ["schraubeStandard1"],
              diffusecolors: ["schraubeStandard1"],
              richtungsvektor: [item.richtungsvektor],
              mutter: [item.uScheibe]

            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          }
        });

        var xWerte = [];
        var yWerte = [];
        var zWerte = [];

        // console.log('punkte');

        dataObject.punkte.forEach(function (item) {

          xWerte.push(Number(item.x));
          yWerte.push(Number(item.y));
          zWerte.push(Number(item.z));

          var p = store.push('punkt', item);
          p.save();
        });

        application.setMinMaxWerte("x", xWerte);
        application.setMinMaxWerte("y", yWerte);
        application.setMinMaxWerte("z", zWerte);

        // console.log('platten');

        dataObject.platte.forEach(function (item) {
          var p = store.push('platte', item);
          p.save();
        });

        // console.log('richtungsvektoren');

        dataObject.richtungsvektoren.forEach(function (item) {
          var r = store.push('richtungsvektor', item);
          r.save();
        });

        // console.log('ansichtsvektoren');

        dataObject.ansichtsvektoren.forEach(function (item) {
          var r = store.push('ansichtsvektoren', item);
          r.save();
        });

        // console.log('orthovektoren');

        dataObject.orthovektoren.forEach(function (item) {
          var r = store.push('orthovektor', item);
          r.save();
        });

        // console.log('screw-properties');

        dataObject.verbindungsmittel.forEach(function (item) {
          var screwProp = store.push('screw-property', item);
          screwProp.save();
        });

        // console.log('massketten');

        x3d.set('ebene', 1);
        dataObject.masskette.forEach(function (item) {
          var massk = store.push('masskette', item);
          var textEbene = x3d.get('ebene');

          // if (textEbene === 1 && massk.get('masstext') !== "" && massk.get('masstext') !== "0.00" && massk.get('masstext') !== "0.0"){
          //   x3d.set('ebene', 2)
          // }else if (textEbene === 2 && massk.get('masstext') !== "" && massk.get('masstext') !== "0.00" && massk.get('masstext') !== "0.0"){
          //   x3d.set('ebene', 1)
          // }
          //
          // massk.set('ebene', textEbene)

          massk.save();
        });

        // console.log('lagewinkel');

        if (typeof dataObject.lagewinkel === "undefined") {
          console.log('LAGEWINKEL in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.lagewinkel.forEach(function (item) {
            var lagewinkel = store.push('lagewinkel', item);
            lagewinkel.save();
          });
        }

        if (typeof dataObject.uScheibe === "undefined") {
          console.log('uScheibe in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.uScheibe.forEach(function (item) {
            var uScheibe = store.push('uscheibe', item);
            uScheibe.save();
          });
        }

        if (typeof dataObject.mutter === "undefined") {
          console.log('mutter in der geladenen Dateie nicht vorhanden');
        } else {
          dataObject.mutter.forEach(function (item) {
            var mutter = store.push('mutter', item);
            mutter.save();
          });
        }

        x3d.set('ergebnisGeladen', true);
        x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
        x3d.set('transformHelper', !x3d.get('transformHelper'));
        self.controllerFor('application').zentriertObjekt();
      });
    },

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      // console.log('vektor.x: '+vektor.x+' bezugsVektor.x: '+bezugsVektor.x+' xx: '+xx);
      // console.log('vektor.y: '+vektor.y+' bezugsVektor.y: '+bezugsVektor.y+' yy: '+yy);
      // console.log('vektor.z: '+vektor.z+' bezugsVektor.z: '+bezugsVektor.z+' zz: '+zz);

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      // console.log('l_vektor: '+l_vektor+' l_bezugsVektor: '+l_bezugsVektor+' winkel: '+winkel);

      return winkel;
    },

    // setBoxsize: function(itemID, traegerID){
    //   var self = this;
    //
    //   var application = self.controllerFor('application');
    //   var x3d = application.get('model').x3ddefault.objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //   var item = bauteile.findBy('id', itemID);
    //   var traeger = bauteile.findBy('id', traegerID);
    //
    //   var y = Math.abs(Number(item.get('punkt').objectAt(0).get('y'))) * 2;
    //   var z = Number(item.get('platte').objectAt(0).get('thickness'));
    //
    //   traeger.get('boxsizes').objectAt(0).set('y', y);
    //   traeger.get('boxsizes').objectAt(0).set('z', z);
    //
    //   if (traegerID === 'hauptTraeger1'){
    //      bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).set('y', y);
    //      bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).set('z', z);
    //   pupsi
    //
    // },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        var selectedScrew = self.selectedNodes[0].data.ArtNr;
        var application = self.controllerFor('application');
        var applicationdata = application.get('model').application.objectAt(0);
        var cid = applicationdata.get('Calculation_ID');

        applicationdata.set('selectedScrew', selectedScrew);

        self.set('loading', true);

        console.log(self.calcid);
        console.log(selectedScrew);

        var server = application.get('server');

        $.ajax({
          type: "POST",
          url: server + "getKompaktergebnis/",
          data: {
            cid: self.calcid,
            artnr: selectedScrew
          }
        }).done(function (data, statusText, xhr) {

          self.controllerFor('supercontroller').logaufruf("getKompaktergebnis", xhr.status);

          console.log(data);

          var ergebnis = self.controllerFor('ergebnis');

          var downloadcenter = self.controllerFor('downloadcenter');
          downloadcenter.set('pdfErstellt', false);
          downloadcenter.set('dxfErstellt', false);

          var html = data;
          var bEnd, bStart;
          bStart = html.indexOf("<div class='headline'");
          bEnd = html.indexOf("</body");
          var body = html.slice(bStart, bEnd);

          ergebnis.set('kompaktergebnis', body.toString());
          ergebnis.set('pdfErstellt', false);
          ergebnis.set('dxfErstellt', false);
          ergebnis.send('setKompaktergebnis');
          self.transitionToRoute('ergebnis');
          self.set('loading', false);
        });
      },

      logNode: function logNode(event) {
        var self = this;
        console.log(self.selectedNodes);
        console.log(self.selectedNodes[0].text);
      }
    }
  });

});