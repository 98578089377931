define('m03/templates/mutte-r', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("transform");
        dom.setAttribute(el1,"class","ember-view schraubenKlasse");
        dom.setAttribute(el1,"bboxcenter","0 0 0");
        dom.setAttribute(el1,"bboxsize","-1,-1,-1");
        dom.setAttribute(el1,"center","0 0 0");
        dom.setAttribute(el1,"scale","1,1,1");
        dom.setAttribute(el1,"scaleorientation","0,0,0,0");
        dom.setAttribute(el1,"render","true");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("shape");
        dom.setAttribute(el2,"class","ember-view");
        dom.setAttribute(el2,"bboxcenter","0,0,0");
        dom.setAttribute(el2,"bboxsize","-1,-1,-1");
        dom.setAttribute(el2,"ispickable","0");
        dom.setAttribute(el2,"render","true");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("appearance");
        dom.setAttribute(el3,"class","ember-view");
        dom.setAttribute(el3,"sorttype","auto");
        dom.setAttribute(el3,"alphaclipthreshold","0.1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("material");
        dom.setAttribute(el4,"class","ember-view");
        dom.setAttribute(el4,"transparency","0");
        dom.setAttribute(el4,"ambientintensity","0.2");
        dom.setAttribute(el4,"emissivecolor","0,0,0");
        dom.setAttribute(el4,"shininess","0.2");
        dom.setAttribute(el4,"specularcolor","0,0,0");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("extrusion");
        dom.setAttribute(el3,"class","ember-view");
        dom.setAttribute(el3,"endcap","true");
        dom.setAttribute(el3,"solid","true");
        dom.setAttribute(el3,"creaseangle","7");
        dom.setAttribute(el3,"ccw","true");
        dom.setAttribute(el3,"usegeocache","true");
        dom.setAttribute(el3,"lit","true");
        dom.setAttribute(el3,"begincap","true");
        dom.setAttribute(el3,"convex","true");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3]);
        var attrMorph0 = dom.createAttrMorph(element0, 'translation');
        var attrMorph1 = dom.createAttrMorph(element0, 'rotation');
        var attrMorph2 = dom.createAttrMorph(element2, 'diffusecolor');
        var attrMorph3 = dom.createAttrMorph(element3, 'crosssection');
        var attrMorph4 = dom.createAttrMorph(element3, 'orientation');
        var attrMorph5 = dom.createAttrMorph(element3, 'scale');
        var attrMorph6 = dom.createAttrMorph(element3, 'spine');
        attribute(env, attrMorph0, element0, "translation", get(env, context, "translation"));
        attribute(env, attrMorph1, element0, "rotation", get(env, context, "rotation"));
        attribute(env, attrMorph2, element2, "diffusecolor", get(env, context, "farbe"));
        attribute(env, attrMorph3, element3, "crosssection", get(env, context, "crosssection"));
        attribute(env, attrMorph4, element3, "orientation", get(env, context, "orientation"));
        attribute(env, attrMorph5, element3, "scale", get(env, context, "scale"));
        attribute(env, attrMorph6, element3, "spine", get(env, context, "spine"));
        return fragment;
      }
    };
  }()));

});