define('m03/controllers/lasteinwirkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    itw: false, //don't change this!
    swg: false, //don't change this!

    debug: true,
    openFromFile: false,
    displayErrors: true,
    loading: false,
    bmlast: "",
    winkelScherkraft: "",
    scherkraft: "",
    axialkraft: "",
    zsver: 0,

    ledTooltip: "",
    nklTooltip: "",

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    verbindungsmittelliste: "",
    nkl: 1,
    led: 1,

    stahl: false,

    timberNA: "DE",

    ttScherkraft: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return "bla bla bl"; //this.get('i18n').t('wertebereich') + " 0.0 - 9999.9 [kN]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttWinkelScherkraft: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.0 - 360.0 [°]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttAxialkraft: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.0 - 9999.0 [kN]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    /* ttAxialkraft: Ember.computed('i18n.locale', function() {
           return this.get('i18n').t('wertebereich') + "0.0 - 9999.0 [kN]";
     }), */

    i18n: Ember['default'].inject.service(),

    validations: {
      nkl: {
        type: "",
        tooltip: "ttNutzungsklasse",
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 3
        }
      },
      scherkraft: {
        type: "k",
        tooltip: "ttScherkraft",
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      winkelScherkraft: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThan: 360.0
        }
      },
      axialkraft: {
        type: "k",
        tooltip: "ttAxialkraft",
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 9999.9
        }
      }
    },

    nklarray: [],

    ledarray: [],

    connectionTypes: [],
    groupOfConnectors: true,
    connectionTypesDB: [{
      id: true,
      name: "verbindungsmittelGruppe"
    }, {
      id: false,
      name: "verbindungsmittelEinzeln"
    }],

    nklHilfetext: "",
    nklHilfetextFocused: false,
    ledHilfetext: "",
    ledHilfetextFocused: false,
    scherkraftHilfetext: "",
    scherkraftHilfetextFocused: false,
    winkelScherkraftHilfetext: "",
    winkelScherkraftHilfetextFocused: false,
    axialkraftHilfetext: "",
    axialkraftHilfetextFocused: false,

    init: function init() {

      this._super();

      this.setSelectFieldsContent();

      var zero = 0;

      this.set('winkelScherkraft', zero.toFixed(1));
      this.set('ledTooltip', this.get('i18n').t('ledTooltipStaendig'));
      this.set('nklTooltip', this.get('i18n').t('nklTooltip1'));
    },

    applyFactor: function applyFactor(m) {
      console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraefteFaktor);
      var result = "";
      for (var p in this[m]) {
        if (this[m].hasOwnProperty(p)) {
          if (this[m][p].type == "l") {
            console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }
      this.set('scherkraft', 0..toFixed(this.kraefteNachkomma));
      this.set('axialkraft', 0..toFixed(this.kraefteNachkomma));
    },

    setTooltips: function setTooltips(m) {

      console.log('##### setTooltips Lasteinwirkung #####');

      var result = "";
      for (var p in this[m]) {
        if (this[m].hasOwnProperty(p)) {
          if (this[m][p].type == "l") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
          } else if (this[m][p].type == "k") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
          }
        }
      }
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in lasteinwirkung: " + values);
      }

      var scherkraftEvent = { target: { name: "scherkraft" } };
      var axialkraftEvent = { target: { name: "axialkraft" } };
      var vWinkelScherkraftEvent = { target: { name: "winkelScherkraft" } };

      this.set('openFromFile', true);
      this.set('openFromFile', true);

      this.set('nkl', parseInt(values.NKL_ID));
      this.set('led', parseInt(values.KLED_ID));
      values.einzelnesVerbindungsmittel !== undefined ? this.set('groupOfConnectors', !values.einzelnesVerbindungsmittel) : false;

      this.set('scherkraft', (parseFloat(values.V_Ed) * self.kraefteFaktor).toFixed(self.kraefteNachkomma));
      this.setX3D((parseFloat(values.V_Ed) * self.kraefteFaktor).toFixed(self.kraefteNachkomma), scherkraftEvent);

      this.set('axialkraft', (values.N_Ed * self.kraefteFaktor).toFixed(self.kraefteNachkomma));
      this.setX3D((parseFloat(values.N_Ed) * self.kraefteFaktor).toFixed(self.kraefteNachkomma), axialkraftEvent);

      this.set('winkelScherkraft', String(values.ALFA_Force_GRAD));
      this.setX3D(parseFloat(values.ALFA_Force_GRAD), vWinkelScherkraftEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setNklassen();
      self.setledarray();
      self.setConnectionTypes();
    },

    setNklassen: function setNklassen() {
      var self = this;
      var indices = [1, 2, 3];

      var maxValues = [];

      if (self.controllerFor('bauteile').get('htfkl').toString().indexOf(".") === -1) {
        maxValues.push(materialien.liste.findBy('IndexNr', self.controllerFor('bauteile').get('htfkl')).MaxNKL);
      } else if (self.controllerFor('bauteile').get('ntfkl').toString().indexOf(".") === -1) {
        maxValues.push(materialien.liste.findBy('IndexNr', self.controllerFor('bauteile').get('ntfkl')).MaxNKL);
      }

      if (self.controllerFor('zwischenschichtverstaerkung').get('blass')) {
        maxValues.push(materialien.liste.findBy('IndexNr', self.controllerFor('zwischenschichtverstaerkung').get('fklBlass')).MaxNKL);
      }

      if (self.controllerFor('zwischenschichtverstaerkung').get('werner')) {

        maxValues.push(materialien.liste.findBy('IndexNr', self.controllerFor('zwischenschichtverstaerkung').get('fklWerner')).MaxNKL);
      }

      maxValues = maxValues.sort(function (a, b) {
        return a - b;
      });
      self.set('validations.nkl.numericality.lessThanOrEqualTo', maxValues[0]);

      self.set('nklarray', self.getSelectFieldContent('nklassen', indices, maxValues[0]));
    },

    setledarray: function setledarray() {
      var self = this;
      var indices = [];
      var maxValue = 9999;

      if (self.get('timberNA') === "DE" || self.get('timberNA') === "AT") {
        indices = [1, 2, 3, 4, 45, 5];
      } else {
        if (self.get('led') === 45) {
          self.set('led', 1);
        }
        indices = [1, 2, 3, 4, 5];
      }

      self.set('ledarray', self.getSelectFieldContent('ledarray', indices, maxValue));
    },

    setConnectionTypes: function setConnectionTypes() {
      var self = this;
      var indices = [true, false];
      var maxValue = 9999;

      self.set('connectionTypes', self.getSelectFieldContent('connectionTypes', indices, maxValue));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes, maxValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            var disabled = false;
            if (db === 'nklassen' && dbContent[k].id > maxValue) {
              disabled = true;
            }

            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'nklassen':
          dbContent = nutzungsklassenDB.nutzungsklassen_de;
          break;
        case 'ledarray':
          dbContent = ledDB.led_de;
          break;
        case 'connectionTypes':
          dbContent = this.get('connectionTypesDB');
          break;
      }

      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {

        if (this.get('winkelScherkraft').indexOf(",") !== -1) {
          this.set('winkelScherkraft', this.get('winkelScherkraft').replace(",", "."));
          this.setfuehrendeNull('winkelScherkraft');
        }

        if (this.get('scherkraft').indexOf(",") !== -1) {
          this.set('scherkraft', this.get('scherkraft').replace(",", "."));
          this.setfuehrendeNull('scherkraft');
        }

        if (this.get('axialkraft').indexOf(",") !== -1) {
          this.set('axialkraft', this.get('axialkraft').replace(",", "."));
          this.setfuehrendeNull('axialkraft');
        }
      }
    }).observes('scherkraft', 'winkelScherkraft', 'axialkraft'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      lasteinwirkung.set('NKL_ID', this.get('nkl'));

      var nklText = this.nklarray[this.get('nkl') - 1].name;
      // var ledText = this.ledarray[this.get('led') - 1].name;

      lasteinwirkung.set('NKL_Text', nklText.toString());
      lasteinwirkung.set('KLED_ID', this.get('led'));
      var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
      lasteinwirkung.set('LED_Text', ledText);

      switch (self.get('led')) {
        case 1:
          self.set('ledTooltip', self.get('i18n').t('ledTooltipStaendig'));
          break;
        case 2:
          self.set('ledTooltip', self.get('i18n').t('ledTooltipLang'));
          break;
        case 3:
          self.set('ledTooltip', self.get('i18n').t('ledTooltipMittel'));
          break;
        case 4:
          self.set('ledTooltip', self.get('i18n').t('ledTooltipKurz'));
          break;
        case 5:
          self.set('ledTooltip', self.get('i18n').t('ledTooltipSehrKurz'));
          break;
      }

      switch (self.get('nkl')) {
        case 1:
          self.set('nklTooltip', self.get('i18n').t('nklTooltip1'));
          break;
        case 2:
          self.set('nklTooltip', self.get('i18n').t('nklTooltip2'));
          break;
        case 3:
          self.set('nklTooltip', self.get('i18n').t('nklTooltip3'));
          break;
      }
    }).observes('led', 'nkl'),

    watchGroupOfConnectors: (function () {
      var self = this;

      this.controllerFor('supercontroller').resetApplication();
      this.controllerFor('application').get('model').lasteinwirkung.objectAt(0).set('einzelnesVerbindungsmittel', !this.get('groupOfConnectors'));
    }).observes('groupOfConnectors'),

    setX3D: function setX3D(value, event) {

      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      switch (event.target.name) {
        case 'scherkraft':
          if (this.errors.get('scherkraft').length === 0) {

            // parseFloat(self.get('scherkraft')).toFixed(2) / self.kraefteFaktor

            x3d.set('scherkraft', (value / self.kraefteFaktor).toFixed(4));
            application.get('model').x3ddefault.objectAt(0).set('scherkraftEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('scherkraftEingetragen', false);
          }
          break;
        case 'axialkraft':
          if (this.errors.get('axialkraft').length === 0) {
            x3d.set('axialkraft', (value / self.kraefteFaktor).toFixed(4));
            application.get('model').x3ddefault.objectAt(0).set('axialkraftEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('axialkraftEingetragen', false);
          }
          break;
        case 'winkelScherkraft':
          if (this.errors.get('winkelScherkraft').length === 0) {
            x3d.set('vWinkelScherkraft', Number(value).toFixed(1));
            application.get('model').x3ddefault.objectAt(0).set('vWinkelScherkraftEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('vWinkelScherkraftEingetragen', false);
          }
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    getStahlname: function getStahlname(index) {
      var self = this;
      var pklassenDB = fklDB_new.fkl_stahl.fklassen;

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i].id === index) {
          // console.log(" ");
          // console.log("#####");
          // console.log(pklassenDB[i].id);
          // console.log(pklassenDB[i].name);
          // console.log(pklassenDB[i].name.toString());
          // console.log(this.get('i18n').t(pklassenDB[i].name).toString());
          // console.log("#####");
          // console.log(" ");

          tmp = this.get('i18n').t(pklassenDB[i].name).toString();
        }
      }

      return tmp;
    },

    observeNKL: (function () {
      var self = this;
      self.send('validation', self.get('nkl'), { target: { name: "nutzungsklasse" } });
    }).observes('nkl'),

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
        var nklText = self.nklarray[self.get('nkl') - 1].name;

        var validation = self.get('isValid');

        if (validation === true) {

          var lasteinwirkung = self.store.createRecord('lasteinwirkung', {

            KLED_ID: self.get('led'),
            LED_Text: ledText,
            NKL_ID: self.get('nkl'),
            NKL_Text: nklText.toString(),
            V_Ed: self.get('bmlast').replace(",", ".")

          });
        }

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            var bauteile = self.controllerFor('bauteile');

            application.set('verbindungsmittelInvalid', true);

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            // // var geometriedata = application.get('model').geometrie.objectAt(0);
            // var geometriedataJSON = JSON.stringify(geometriedata);
            //
            // var bauteiledata = application.get('model').bauteile.objectAt(0);
            // var bauteiledataJSON = JSON.stringify(bauteiledata);
            //
            // var zwverdata = application.get('model').zwischenschichtverstaerkung.objectAt(0);
            // var zwverdataJSON = JSON.stringify(zwverdata);
            //
            // var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
            // var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);
            //
            // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
            //
            // var optionendata = application.get('model').optionen.objectAt(0);
            // var optionendataJSON = JSON.stringify(optionendata);
            //
            // if (self.debug) {
            //   console.log("projektdatendata: " + projektdatendataJSON);
            //   console.log("applicationdataJSON: " + applicationdataJSON);
            //   console.log("geometriedataJSON: " + geometriedataJSON);
            //   console.log("bauteiledataJSON: " + bauteiledataJSON);
            //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
            // }
            //
            // var uebergabedaten = $.extend(true, JSON.parse(geometriedataJSON), JSON.parse(bauteiledataJSON), JSON.parse(zwverdataJSON), JSON.parse(anschlussgeometriedataJSON), JSON.parse(lasteinwirkungdataJSON), JSON.parse(optionendataJSON));
            //
            // var shearconnection = {
            //   "ConnectionTyp": geometriedata.get('ConnectionType'),
            //   "von1zu2": true,
            //   "ZwischenSchichtVorh": geometriedata.get('ZwischenSchichtVorh'),
            //   "VerstaerkungVorh": geometriedata.get('VerstaerkungVorh'),
            //   "ShearPlaneMultiplicator": geometriedata.get('ShearPlaneMultiplicator'),
            //   "Setztiefe": Number(geometriedata.get('Setztiefe')).toFixed(1),
            //   "ShowMessageOnError": false,
            //   "StarreZwischenSchicht": zwverdata.get('StarreZwischenSchicht'),
            //   "Klammer30": geometriedata.get('Klammer30')
            // };
            //
            //
            // if (bauteile.get('htStahl') === true) {
            //   var timberelement1 = {
            //     "Querschnittswerte": {
            //       "t_Fastener": bauteiledata.get('TimberElement2_b'),
            //       "b": bauteiledata.get('TimberElement2_b'),
            //       "h": bauteiledata.get('TimberElement2_h')
            //     },
            //     "ALFA_RAD_LoadGrain": "0.0",
            //     "vorgebohrt": bauteiledata.get('TimberElement1Predrilled'),
            //     "MatKey": bauteiledata.get('MatKeyTimberElement1'),
            //     "FKLKey": bauteiledata.get('FKLKeyTimberElement1')
            //   };
            // } else {
            //   var timberelement1 = {
            //     "Querschnittswerte": {
            //       "QuerschnittsID": "1",
            //       "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
            //       "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
            //       "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(2)
            //     },
            //     "ALFA_RAD_LoadGrain": "0.0",
            //     "vorgebohrt": bauteiledata.get('TimberElement1Predrilled'),
            //     "MatKey": bauteiledata.get('MatKeyTimberElement1'),
            //     "FKLKey": bauteiledata.get('FKLKeyTimberElement1')
            //   };
            // }
            //
            // var timberelement2 = {
            //   "Querschnittswerte": {
            //     "QuerschnittsID": "1",
            //     "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
            //     "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
            //     "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(2)
            //   },
            //   "ALFA_RAD_LoadGrain": "0.0",
            //   "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
            //   "MatKey": bauteiledata.get('MatKeyTimberElement2'),
            //   "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
            // };
            //
            // if (self.get('zsver') == 0) {
            //   var interlayerelement = {
            //     "Querschnittswerte": {
            //       "QuerschnittsID": "1",
            //       "t_Fastener": 0.0,
            //       "b": 0.0,
            //       "h": 0.0
            //     },
            //     "ALFA_RAD_LoadGrain": 0.0,
            //     "vorgebohrt": false,
            //     "MatKey": 0,
            //     "FKLKey": 0
            //   };
            // } else if (self.get('zsver') == 1) {
            //   var interlayerelement = {
            //     "Querschnittswerte": {
            //       "QuerschnittsID": "1",
            //       "t_Fastener": zwverdata.get('dickeBlass'),
            //       "b": zwverdata.get('dickeBlass'),
            //       "h": 0.0
            //     },
            //     "ALFA_RAD_LoadGrain": 0.0,
            //     "vorgebohrt": zwverdata.get('materialBlassPredrilled'),
            //     "MatKey": zwverdata.get('materialBlass'),
            //     "FKLKey": zwverdata.get('fklBlass')
            //   };
            // } else if (self.get('zsver') == 2) {
            //   var interlayerelement = {
            //     "Querschnittswerte": {
            //       "QuerschnittsID": "1",
            //       "t_Fastener": zwverdata.get('dickeWerner'),
            //       "b": zwverdata.get('dickeWerner'),
            //       "h": 0.0
            //     },
            //     "ALFA_RAD_LoadGrain": 0.0,
            //     "vorgebohrt": zwverdata.get('materialWernerPredrilled'),
            //     "MatKey": zwverdata.get('materialWerner'),
            //     "FKLKey": zwverdata.get('fklWerner')
            //   };
            // }
            //
            // if(bauteile.get('htStahl') === true){
            //   var steelplate = {
            //     // "Bezeichnung": "S235",
            //     "Bezeichnung": self.getStahlname(Number(bauteile.get('htfkl'))),
            //     "f_yk": Number(bauteile.get('htfkl')).toFixed(1),
            //     "Querschnittswerte": {
            //       "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
            //       "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
            //       "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(1)
            //     },
            //     "ALFA_RAD_LoadGrain": 0.0,
            //     "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
            //     "MatKey": bauteiledata.get('MatKeyTimberElement2'),
            //     "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
            //   };
            // }else{
            //   var steelplate = {
            //     // "Bezeichnung": "S235",
            //     "Bezeichnung": self.getStahlname(Number(bauteile.get('ntfkl'))),
            //     "f_yk": Number(bauteile.get('ntfkl')).toFixed(1),
            //     "Querschnittswerte": {
            //       "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(1),
            //       "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(1),
            //       "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(1)
            //     },
            //     "ALFA_RAD_LoadGrain": 0.0,
            //     "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
            //     "MatKey": bauteiledata.get('MatKeyTimberElement2'),
            //     "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
            //   };
            // }
            //
            // var berechnungsoptionen = {
            // 	"faserversatz": optionendata.get('verbindungsmittelVersetzt')
            // }
            //
            // uebergabedaten.ShearConnection = shearconnection;
            // uebergabedaten.TimberElement1 = timberelement1;
            // uebergabedaten.TimberElement2 = timberelement2;
            // uebergabedaten.InterlayerElement = interlayerelement;
            // uebergabedaten.SteelPlate = steelplate;
            // uebergabedaten.berechnungsoptionen = berechnungsoptionen;

            // var JSONdata = JSON.stringify(uebergabedaten);
            var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

            console.log('JSONdata');
            console.log(JSONdata);

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            // if (self.debug) {
            //   console.log("Kennung: " + applicationdataJSON);
            //   console.log(' ');
            //   console.log('#######################################');
            //   console.log(' ');
            //   console.log("Projektdaten: " + projektdatendataJSON);
            //   console.log(' ');
            //   console.log('#######################################');
            //   console.log(' ');
            //   console.log("Paras: "+JSONdata);
            // }

            var initialized = applicationdata.get('initialized');

            var server = application.get('server');
            var pfad = application.get('pfad');

            console.log('server: ' + server);
            console.log('pfad: ' + pfad);

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: server + "initialisiereBerechnung/",
                //url: "http://136.243.4.143/berechnungsservice/x3d/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m03",
                  pfad: pfad
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                console.log('data:');
                console.log(data);

                var cid = data;

                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: server + "erzeugeSchraubenliste/",
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log('schraubenbaum:');
                  console.log(data);

                  if (Number(JSON.parse(data).Item1) === 0) {
                    var liste = JSON.parse(JSON.parse(data).Item2);
                    self.verbindungsmittelliste = liste;
                    var core = treetools.buildTree(liste, true);
                    vmittel.set('schraubenliste', core);
                    vmittel.set('keineSchraubenliste', false);
                  } else if (Number(JSON.parse(data).Item1) === 1) {
                    application.set('verbindungsmittelInvalid', false);
                    application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                    vmittel.set('infoText', JSON.parse(data).Item3);
                    vmittel.set('keineSchraubenliste', true);
                  }

                  vmittel.set('calcid', cid);
                  vmittel.set('loading', false);

                  applicationdata.set('treeLoaded', true);

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              $.ajax({
                type: "POST",
                url: server + "erzeugeSchraubenliste/",
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                if (Number(JSON.parse(data).Item1) === 0) {
                  var liste = JSON.parse(JSON.parse(data).Item2);
                  self.verbindungsmittelliste = liste;
                  var core = treetools.buildTree(liste, true);
                  vmittel.set('schraubenliste', core);
                  vmittel.set('keineSchraubenliste', false);
                } else if (Number(JSON.parse(data).Item1) === 1) {
                  application.set('verbindungsmittelInvalid', false);
                  application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                  vmittel.set('infoText', JSON.parse(data).Item3);
                  vmittel.set('keineSchraubenliste', true);
                }

                vmittel.set('loading', false);
                vmittel.set('calcid', cid);

                applicationdata.set('treeLoaded', true);

                self.set('loading', false);
              });
            }
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);

            application.set('verbindungsmittelInvalid', true);

            var lasteinwirkung = self.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

            lasteinwirkung.set('V_Ed', parseFloat(self.get('scherkraft')).toFixed(2) / self.kraefteFaktor);
            lasteinwirkung.set('ALFA_Force_GRAD', parseFloat(self.get('winkelScherkraft')).toFixed(1));
            lasteinwirkung.set('N_Ed', parseFloat(self.get('axialkraft')).toFixed(2) / self.kraefteFaktor);
            // self.set('bmlast', parseFloat(self.get('bmlast').replace(",", ".")).toFixed(1));
          } else {
              self.set('displayErrors', true);
              application.set('lasteinwirkungInvalid', true);
            }
        });
      },

      winkelScherkraftIsSelected: function winkelScherkraftIsSelected() {
        var vWinkelScherkraftAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vWinkelScherkraftAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vWinkelScherkraftAktiv', !vWinkelScherkraftAktiv);

        if (this.get('winkelScherkraft') !== "") {
          var winkelScherkraftwork = parseFloat(this.get('winkelScherkraft').replace(",", "."));
          var winkelScherkraftrounded = winkelScherkraftwork.toFixed(1);
          this.set('winkelScherkraft', winkelScherkraftrounded);
          this.send('validation', winkelScherkraftrounded, { target: { name: "winkelScherkraft" } });
        }

        if (vWinkelScherkraftAktiv === false) {
          document.getElementsByName('winkelScherkraft')[0].setSelectionRange(0, this.get('winkelScherkraft').length);
        }
      },

      scherkraftIsSelected: function scherkraftIsSelected() {
        var scherkraftAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('scherkraftAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('scherkraftAktiv', !scherkraftAktiv);

        if (this.get('scherkraft') !== "") {
          var scherkraftwork = parseFloat(this.get('scherkraft').replace(",", "."));
          var scherkraftrounded = scherkraftwork.toFixed(this.kraefteNachkomma);
          this.set('scherkraft', scherkraftrounded);
          this.send('validation', scherkraftrounded, { target: { name: "scherkraft" } });
        }

        if (scherkraftAktiv === false) {
          document.getElementsByName('scherkraft')[0].setSelectionRange(0, this.get('scherkraft').length);
        }
      },

      axialkraftIsSelected: function axialkraftIsSelected() {

        var axialkraftAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('axialkraftAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('axialkraftAktiv', !axialkraftAktiv);

        if (this.get('axialkraft') !== "") {
          var axialkraftwork = parseFloat(this.get('axialkraft').replace(",", "."));
          var axialkraftrounded = axialkraftwork.toFixed(this.kraefteNachkomma);
          this.set('axialkraft', axialkraftrounded);
          this.send('validation', axialkraftrounded, { target: { name: "axialkraft" } });
        }

        if (axialkraftAktiv === false) {
          document.getElementsByName('axialkraft')[0].setSelectionRange(0, this.get('axialkraft').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // Ende actions

    // hilfeTexte display observes

    observes_nklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Lasteinwirkung", "nkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("lasteinwirkung", hilfetextArray);
    }).observes('nkl'),

    observes_ledHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Lasteinwirkung", "led", "0");
      self.controllerFor("supercontroller").setHilfetexte("lasteinwirkung", hilfetextArray);
    }).observes('led'),

    observes_scherkraftHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Lasteinwirkung", "scherkraft", "0");
      self.controllerFor("supercontroller").setHilfetexte("lasteinwirkung", hilfetextArray);
    }).observes('scherkraft'),

    observes_winkelScherkraftHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Lasteinwirkung", "winkelScherkraft", "0");
      self.controllerFor("supercontroller").setHilfetexte("lasteinwirkung", hilfetextArray);
    }).observes('winkelScherkraft'),

    observes_axialkraftHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Lasteinwirkung", "axialkraft", "0");
      self.controllerFor("supercontroller").setHilfetexte("lasteinwirkung", hilfetextArray);
    }).observes('axialkraft'),

    // hilfeTexte screwProperties

    display_nklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('nklHilfetext'));;
    }).property('nklHilfetext'),

    display_ledHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ledHilfetext'));;
    }).property('ledHilfetext'),

    display_scherkraftHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('scherkraftHilfetext'));;
    }).property('scherkraftHilfetext'),

    display_winkelScherkraftHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelScherkraftHilfetext'));;
    }).property('winkelScherkraftHilfetext'),

    display_axialkraftHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('axialkraftHilfetext'));;
    }).property('axialkraftHilfetext')

  });

});