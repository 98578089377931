define('m03/templates/components/schraub-e', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.0",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "uscheib-e", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model")}, child0, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("shape");
        dom.setAttribute(el1,"class","ember-view");
        dom.setAttribute(el1,"bboxcenter","0,0,0");
        dom.setAttribute(el1,"bboxsize","-1,-1,-1");
        dom.setAttribute(el1,"ispickable","0");
        dom.setAttribute(el1,"render","true");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("appearance");
        dom.setAttribute(el2,"class","ember-view");
        dom.setAttribute(el2,"sorttype","auto");
        dom.setAttribute(el2,"alphaclipthreshold","0.1");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("material");
        dom.setAttribute(el3,"class","ember-view");
        dom.setAttribute(el3,"transparency","0");
        dom.setAttribute(el3,"ambientintensity","0.2");
        dom.setAttribute(el3,"emissivecolor","0,0,0");
        dom.setAttribute(el3,"shininess","0.2");
        dom.setAttribute(el3,"specularcolor","0,0,0");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("extrusion");
        dom.setAttribute(el2,"class","ember-view");
        dom.setAttribute(el2,"endcap","true");
        dom.setAttribute(el2,"solid","true");
        dom.setAttribute(el2,"creaseangle","7");
        dom.setAttribute(el2,"ccw","true");
        dom.setAttribute(el2,"usegeocache","true");
        dom.setAttribute(el2,"lit","true");
        dom.setAttribute(el2,"begincap","true");
        dom.setAttribute(el2,"convex","true");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" U-Scheibe ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [3]);
        var attrMorph0 = dom.createAttrMorph(element1, 'diffusecolor');
        var attrMorph1 = dom.createAttrMorph(element2, 'crosssection');
        var attrMorph2 = dom.createAttrMorph(element2, 'orientation');
        var attrMorph3 = dom.createAttrMorph(element2, 'scale');
        var attrMorph4 = dom.createAttrMorph(element2, 'spine');
        var morph0 = dom.createMorphAt(fragment,4,4,contextualElement);
        dom.insertBoundary(fragment, null);
        attribute(env, attrMorph0, element1, "diffusecolor", get(env, context, "farbe"));
        attribute(env, attrMorph1, element2, "crosssection", get(env, context, "view.model.firstObject.crosssectionString"));
        attribute(env, attrMorph2, element2, "orientation", get(env, context, "view.model.firstObject.orientationString"));
        attribute(env, attrMorph3, element2, "scale", get(env, context, "view.model.firstObject.scaleString"));
        attribute(env, attrMorph4, element2, "spine", get(env, context, "view.model.firstObject.spineString"));
        block(env, morph0, context, "if", [get(env, context, "uscheibeAnzeigen")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});