define('m03/controllers/bauteil2', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    itw: false, //don't change this!
    swg: false, //don't change this!
    firm: "WUERTH", //don't change this!

    reiterAnschlussgeometrieAnzeigen: false, //don't change this!

    timberCode: "EC 5 NAD (D)", //don't change this!

    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,

    ttBreiteNT: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.4 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttb1: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttb2: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttbeta1: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 175 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttbeta2: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 175 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttbeta3: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 360 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    i18n: Ember['default'].inject.service(),
    self: undefined,
    haupthauptTraeger1: '',
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    ntfkl: 5,
    ntholz: 0,
    winkel: "",
    ntStahl: false,
    laubholz: false,
    korrosionsrisiko: false,

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    materialienOption: 1,

    validations: {
      ntbreite: {
        type: "l",
        tooltip: "ttBreiteNT",
        numericality: {
          greaterThanOrEqualTo: 1.8,
          lessThanOrEqualTo: 9999.9
        }
      },
      nthoehe: {
        type: "l",
        tooltip: "ttHoeheNT",
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      b1: {
        type: "l",
        tooltip: "ttb1",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      b2: {
        type: "l",
        tooltip: "ttb2",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      beta1: {
        type: "w",
        tooltip: "ttbeta1",
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 175
        }
      },
      beta2: {
        type: "w",
        tooltip: "ttbeta2",
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 175
        }
      },
      beta3: {
        type: "w",
        tooltip: "ttbeta3",
        numericality: {
          greaterThan: 0,
          lessThan: 360
        }
      }
    },

    traeger: [{
      name: "Haupt-/Nebenträger",
      value: true
    }, {
      name: "Stütze/Nebenträger",
      value: false
    }],

    ntmaterial: [],
    ntfklarray: [],

    vgebohrt: [],
    te2vorgebohrt: false,
    te2laubholz: false,

    variante3focused: true,
    variante4focused: false,

    minimalDickenDB: {
      0: 2.4,
      1: 2.4,
      13: 2.4,
      21: 0.6,
      20: 0.6,
      7: 0.6,
      8: 0.8,
      12: 2.4,
      1000: 0.1,
      30: 2.4
    },

    ntholzHilfetext: "",
    ntholzHilfetextFocused: false,
    ntfklHilfetext: "",
    ntfklHilfetextFocused: false,
    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,
    ntbreiteHilfetext: "",
    ntbreiteHilfetextFocused: false,
    nthoeheHilfetext: "",
    nthoeheHilfetextFocused: false,

    b1Hilfetext: "",
    b1HilfetextFocused: false,
    b2Hilfetext: "",
    b2HilfetextFocused: false,
    beta1Hilfetext: "",
    beta1HilfetextFocused: false,
    bet2Hilfetext: "",
    bet2HilfetextFocused: false,
    beta3Hilfetext: "",
    beta3HilfetextFocused: false,

    letzteAnschlussVrianteBauteil2: 52,
    letzteAnschnittVrianteBauteil2: 11,

    b1: "",
    b2: "",
    beta1: "",
    beta2: "",
    beta3: "",

    b1InputDisabled: true,
    b2InputDisabled: true,
    beta1InputDisabled: true,
    beta2InputDisabled: true,
    beta3InputDisabled: true,

    abschnittOben: true,
    abschnittUnten: false,

    grafik1: "assets/images/rechts_unten1.png",
    grafik2: "assets/images/rechts_unten2.png",
    grafik3: "assets/images/rechts_unten3.png",
    grafik4: "assets/images/rechts_unten4.png",

    displayAnschinttGeometrie: true,

    init: function init() {
      this._super();
      var self = this;

      this.set('ntbreite', "");
      this.set('nthoehe', "");

      this.set("b1", "0.0");
      this.set("b2", "0.0");
      this.set("beta1", "90.0");
      this.set("beta2", "90.0");
      this.set("beta3", "180.0");

      self.setSelectFieldsContent();

      this.set('haupthauptTraeger1', this.get('i18n').t('bemessungslast').toString());
    },

    applyFactor: function applyFactor(m) {
      console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraefteFaktor);
      var result = "";
      for (var p in this[m]) {
        console.log("for");
        if (this[m].hasOwnProperty(p)) {
          console.log(p);
          if (this[m][p].type == "l") {
            console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }
    },

    setTooltips: function setTooltips(m) {

      console.log('##### setTooltips Bauteile #####');

      var result = "";
      for (var p in this[m]) {
        if (this[m].hasOwnProperty(p)) {
          if (this[m][p].type == "l") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
          } else if (this[m][p].type == "k") {
            this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + ' ' + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " + parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
          }
        }
      }
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var ntbreiteEvent = {
        target: {
          name: "ntbreite"
        }
      };
      var nthoeheEvent = {
        target: {
          name: "nthoehe"
        }
      };

      this.setX3D(parseInt(values.Setztiefe), setztiefeEvent);

      this.set('openFromFile', true);

      this.set('winkel', values.ALFA_Elements_GRAD);
      this.setX3D(values.ALFA_Elements_GRAD, winkelEvent);

      if (values.ConnectionType === "HH" || values.ConnectionType === "HHH" || values.ConnectionType === "HSH") {
        this.set('ntholz', Number(values.MatKeyTimberElement2));
        this.set('ntfkl', Number(values.FKLKeyTimberElement2));
      } else {
        this.set('ntholz', Number(values.MatKeyTimberElement1));
        this.set('ntfkl', Number(values.FKLKeyTimberElement1));
      }

      this.set('ntbreite', (values.TimberElement2_b * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.TimberElement2_b * self.laengenFaktor).toFixed(self.laengenNachkomma), ntbreiteEvent);

      this.set('nthoehe', (values.TimberElement2_h * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.TimberElement2_h * self.laengenFaktor).toFixed(self.laengenNachkomma), nthoeheEvent);

      this.set('abschnittOben', values.abschnittOben);
      this.set('abschnittUnten', values.abschnittUnten);

      this.set('b1', Number(values.b1));
      this.set('b2', Number(values.b2));
      this.set('beta1', Number(values.beta1));
      this.set('beta2', Number(values.beta2));
      this.set('beta3', Number(values.beta3));

      this.set('korrosionsrisiko', values.CorrosionResistanceNecessary);

      this.set('te2vorgebohrt', values.TimberElement2Predrilled);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setNtMaterialien();
      self.setNtFKL();
      self.setVgebohrt();
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    setNtMaterialien: function setNtMaterialien() {
      var self = this;
      var indices = new Array();

      if (self.get('firm') === 'ITW' && self.get('timberCode') !== "EC 5 NAD (D)") {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.materialien.findBy('id', self.get('materialienOption')).alternativeValues;
      } else {
        indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.materialien.findBy('id', self.get('materialienOption')).values;
      }

      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setNtFKL: function setNtFKL() {
      var self = this;
      var indices = [];

      indices = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).values;
      self.set('ntfkl', materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).defaultValue);

      if (this.get('ntholz') === 1) {
        // indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        // self.set('htfkl', 20);
      } else if (self.get('ntholz') === 13) {
          // indices = [30, 31, 34, 32];
          self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
          // self.set('htfkl', 30);
        } else if (this.get('ntholz') === 0) {
            // indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
            self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
            // self.set('htfkl', 5);
          } else if (this.get('ntholz') === 30) {
              // indices = [301];
              self.set('ntfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
              // self.set('htfkl', 301);
            } else if (this.get('ntholz') === 21) {
                // indices = [110, 111, 112, 113];
                self.set('ntfklarray', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
                // self.set('htfkl', 110);
              } else if (this.get('ntholz') === 20) {
                  // indices = [125, 126, 127];
                  self.set('ntfklarray', self.getSelectFieldContent('fkl_osb_platten', indices));
                  // self.set('htfkl', 125);
                } else if (this.get('ntholz') === 7) {
                    // indices = [160, 170, 180, 190];
                    self.set('ntfklarray', self.getSelectFieldContent('fkl_kunstharzgebundene_holzspanplatten', indices));
                    // self.set('htfkl', 160);
                  } else if (this.get('ntholz') === 8) {
                      // indices = [200];
                      self.set('ntfklarray', self.getSelectFieldContent('fkl_zementgebundene_holzspanplatten', indices));
                      // self.set('htfkl', 200);
                    } else if (this.get('ntholz') === 12) {
                        // indices = [50, 51, 52];
                        self.set('ntfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
                        // self.set('htfkl', 50);
                      } else if (this.get('ntholz') === 1000) {
                          // indices = [23.5, 35.5];
                          self.set('ntfklarray', self.getSelectFieldContent('fkl_stahl', indices));
                          // self.set('htfkl', 23.5);
                        } else if (this.get('ntholz') === 9) {
                            // indices = [23.5, 35.5];
                            self.set('ntfklarray', self.getSelectFieldContent('fkl_holzfaserplatten', indices));
                            // self.set('htfkl', 23.5);
                          } else if (this.get('ntholz') === 22) {
                              // indices = [23.5, 35.5];
                              self.set('ntfklarray', self.getSelectFieldContent('fkl_gipskartonplatten', indices));
                              // self.set('htfkl', 23.5);
                            }
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_baufurniersperrholz':
          dbContent = fklDB_new.fkl_baufurniersperrholz.fklassen;
          break;
        case 'fkl_osb_platten':
          dbContent = fklDB_new.fkl_osb_platten.fklassen;
          break;
        case 'fkl_kunstharzgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_kunstharzgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_zementgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_zementgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'fkl_stahl':
          dbContent = fklDB_new.fkl_stahl.fklassen;
          break;
        case 'fkl_holzfaserplatten':
          dbContent = fklDB_new.fkl_holzfaserplatten.fklassen;
          break;
        case 'fkl_gipskartonplatten':
          dbContent = fklDB_new.fkl_gipskartonplatten.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
          this.setfuehrendeNull('ntbreite');
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
          this.setfuehrendeNull('nthoehe');
        }

        if (this.get('winkel').indexOf(",") !== -1) {
          this.set('winkel', this.get('winkel').replace(",", "."));
          this.setfuehrendeNull('winkel');
        }

        if (this.get('b1').indexOf(",") !== -1) {
          this.set('b1', this.get('b1').replace(",", "."));
        }

        if (this.get('b2').indexOf(",") !== -1) {
          this.set('b2', this.get('b2').replace(",", "."));
        }

        if (this.get('beta1').indexOf(",") !== -1) {
          this.set('beta1', this.get('beta1').replace(",", "."));
        }

        if (this.get('beta2').indexOf(",") !== -1) {
          this.set('beta2', this.get('beta2').replace(",", "."));
        }

        if (this.get('beta3').indexOf(",") !== -1) {
          this.set('beta3', this.get('beta3').replace(",", "."));
        }
      }
    }).observes('ntbreite', 'nthoehe', 'winkel', 'b1', 'b2', 'beta1', 'bet2', 'beta3'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    watchNtMaterial: (function () {
      var self = this;

      // if (self.get('ntholz') === 12 || self.get('ntholz') === 30) {
      //   self.set('te2laubholz', true);
      //   self.set('te2vorgebohrt', true);
      // } else {
      //   self.set('te2laubholz', false);
      // }

      self.set('te2laubholz', vorgebohrt.materialien.findBy('id', this.get('ntholz')).laubholz);
      self.set('te2vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('ntholz')).value);

      // var tmp = self.minimalDickenDB[self.get('ntholz')];
      var tmpMin = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).minimalDicke;
      var tmpMax = materialienDB.m03.findBy('firm', self.get('firm')).indices.mittelbauteil.festigkeitsklassen.findBy('id', this.get('ntholz')).maximalDicke;

      self.set('validations.ntbreite.numericality.greaterThanOrEqualTo', tmpMin * self.laengenFaktor);
      self.set('validations.ntbreite.numericality.lessThanOrEqualTo', tmpMax * self.laengenFaktor);
      self.set('ttBreiteNT', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.ntbreite.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.ntbreite.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");

      if (self.get('openFromFile') === true) {
        this.send('validation');
      } else {
        this.set('openFromFile', true);
        this.send('validation');
        this.set('openFromFile', false);
      }

      self.setNtFKL();

      self.send("setSchraubenbildWerte", "TE2", "0", "90", "90", "variante3focused", "variante4focused");
    }).observes('ntholz'),

    watchKorrosionsrisiko: (function () {
      var self = this;
      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);

      bauteile.set('CorrosionResistanceNecessary', self.get('korrosionsrisiko'));
    }).observes('korrosionsrisiko'),

    watchComboboxes: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

      if (self.get('htStahl') === true) {
        bauteile.set('MatKeyTimberElement1', this.get('ntholz'));
        bauteile.set('FKLKeyTimberElement1', this.get('ntfkl'));
      } else {

        bauteile.set('MatKeyTimberElement2', this.get('ntholz'));
        bauteile.set('FKLKeyTimberElement2', this.get('ntfkl'));
      }

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('winkelScherkraft'), { target: { name: "winkelScherkraft" } });
    }).observes('ntholz', 'ntfkl'),

    watchTe2Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElement2Predrilled', self.get('te2vorgebohrt'));
    }).observes('te2vorgebohrt'),

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var anschlussgeometrie = this.controllerFor('anschlussgeometrie');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var self = this;

      var anschlgeom = self.controllerFor('anschlussgeometrie');

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(2);

      switch (event.target.name) {
        case 'ntbreite':
          if (this.errors.ntbreite.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value / Number(self.get('laengenFaktor')));
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', true / Number(self.get('laengenFaktor')));
            self.controllerFor('supercontroller').setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', false);
          }
          break;
        case 'nthoehe':
          if (this.errors.nthoehe.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungBlass1').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));
            bauteile.findBy('id', 'verstaerkungBlass2').get('boxsizes').objectAt(0).set('y', value / Number(self.get('laengenFaktor')));

            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', true);
            if (self.get('itw') === true) {
              anschlussgeometrie.setMindestueberstand();
            }
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', false);
          }
          break;

        case 'b1':
          if (this.errors.b1.length === 0) {
            x3d.set('b1', x3d.get('vorzeichenBWerte') * value);
            x3d.set('b1Eingetragen', true);
          } else {
            x3d.set('b1Eingetragen', false);
          }
          break;
        case 'b2':
          if (this.errors.b2.length === 0) {
            // x3d.set('b2', self.get('vorzeichenBWerte') * value);
            x3d.set('b2', value);
            x3d.set('b2Eingetragen', true);
          } else {
            x3d.set('b2Eingetragen', false);
          }
          break;
        case 'beta1':
          if (this.errors.beta1.length === 0) {
            x3d.set('beta1', value);
            x3d.set('beta1Eingetragen', true);

            if (self.get('beta2InputDisabled') === true) {
              self.set('beta2', (180 - value).toFixed(1).toString());
            }
          } else {
            x3d.set('beta1Eingetragen', false);
          }
          break;
        case 'beta2':
          if (this.errors.beta2.length === 0) {
            x3d.set('beta2', value);
            x3d.set('beta2Eingetragen', true);
          } else {
            x3d.set('beta2Eingetragen', false);
          }
          break;
        case 'beta3':
          if (this.errors.beta3.length === 0) {
            x3d.set('beta3', value);
            x3d.set('beta3Eingetragen', true);
          } else {
            x3d.set('beta3Eingetragen', false);
          }
          break;
      }

      if (application.get('swg') === false && self.get('itw') === true) {
        anschlussgeometrie.setMindestueberstand();
      }

      if (Number(x3d.get('vorzeichenBWerte')) !== 0) {
        anschlussgeometrie.setGeometrieMittelbauteil();
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('arc2dPositionTriggern', !application.get('model').x3ddefault.objectAt(0).get('arc2dPositionTriggern'));
      self.controllerFor('application').zentriertObjekt();
    },

    ntBauBuche: (function () {
      if (Number(this.get('ntholz')) === 30) {
        return true;
      }
      return false;
    }).property('ntholz'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.get('openFromFile')) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('bauteil2Invalid', false);

            self.resetVerbindungsmittel();

            var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);

            bauteile.set('TimberElement2_b', (parseFloat(self.get('ntbreite')).toFixed(2) / self.laengenFaktor).toFixed(2));
            bauteile.set('TimberElement2_h', (parseFloat(self.get('nthoehe')).toFixed(2) / self.laengenFaktor).toFixed(2));

            var anschlussgeometrie = self.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

            anschlussgeometrie.set('abschnittOben', self.get('abschnittOben'));
            anschlussgeometrie.set('abschnittUnten', self.get('abschnittUnten'));

            anschlussgeometrie.set('b1', parseFloat(Math.abs(self.get('b1'))).toFixed(1));
            anschlussgeometrie.set('b2', parseFloat(Math.abs(self.get('b2'))).toFixed(1));
            anschlussgeometrie.set('beta1', parseFloat(self.get('beta1')).toFixed(1));
            anschlussgeometrie.set('beta2', parseFloat(self.get('beta2')).toFixed(1));
            anschlussgeometrie.set('beta3', parseFloat(self.get('beta3')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('bauteil2Invalid', true);
          }
        });
      },

      ntbreiteIsSelected: function ntbreiteIsSelected() {
        var ntbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ntbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntbreiteAktiv', !ntbreiteaktiv);

        if (this.get('ntbreite') !== "") {
          var ntbreitework = parseFloat(this.get('ntbreite').replace(",", "."));
          var ntbreiterounded = ntbreitework.toFixed(this.laengenNachkomma);
          this.set('ntbreite', ntbreiterounded);
          this.send('validation', ntbreiterounded, {
            target: {
              name: "ntbreite"
            }
          });
        }

        if (ntbreiteaktiv === false) {
          document.getElementsByName('ntbreite')[0].setSelectionRange(0, this.get('ntbreite').length);
        }
      },

      nthoeheIsSelected: function nthoeheIsSelected() {
        var nthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('nthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('nthoeheAktiv', !nthoeheaktiv);

        if (this.get('nthoehe') !== "") {
          var nthoehework = parseFloat(this.get('nthoehe').replace(",", "."));
          var nthoeherounded = nthoehework.toFixed(this.laengenNachkomma);
          this.set('nthoehe', nthoeherounded);
          this.send('validation', nthoeherounded, {
            target: {
              name: "nthoehe"
            }
          });
        }

        if (nthoeheaktiv === false) {
          document.getElementsByName('nthoehe')[0].setSelectionRange(0, this.get('nthoehe').length);
        }
      },

      b1IsSelected: function b1IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('b1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('b1Aktiv', !aktiv);

        if (this.get('b1') !== "") {
          var value = parseFloat(this.get('b1').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('b1', valuerounded);
          this.send('validation', valuerounded, { target: { name: "b1" } });
        }

        if (aktiv === false) {
          document.getElementsByName('b1')[0].setSelectionRange(0, this.get('b1').length);
        }
      },

      b2IsSelected: function b2IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('b2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('b2Aktiv', !aktiv);

        if (this.get('b2') !== "") {
          var value = parseFloat(this.get('b2').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('b2', valuerounded);
          this.send('validation', valuerounded, { target: { name: "b2" } });
        }

        if (aktiv === false) {
          document.getElementsByName('b2')[0].setSelectionRange(0, this.get('b2').length);
        }
      },

      beta1IsSelected: function beta1IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('beta1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('beta1Aktiv', !aktiv);

        if (this.get('beta1') !== "") {
          var value = parseFloat(this.get('beta1').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('beta1', valuerounded);
          this.send('validation', valuerounded, { target: { name: "beta1" } });
        }

        if (aktiv === false) {
          document.getElementsByName('beta1')[0].setSelectionRange(0, this.get('beta1').length);
        }
      },

      beta2IsSelected: function beta2IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('beta2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('beta2Aktiv', !aktiv);

        if (this.get('beta2') !== "") {
          var value = parseFloat(this.get('beta2').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('beta2', valuerounded);
          this.send('validation', valuerounded, { target: { name: "beta2" } });
        }

        if (aktiv === false) {
          document.getElementsByName('beta2')[0].setSelectionRange(0, this.get('beta2').length);
        }
      },

      beta3IsSelected: function beta3IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('beta3Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('beta3Aktiv', !aktiv);

        if (this.get('beta3') !== "") {
          var value = parseFloat(this.get('beta3').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('beta3', valuerounded);
          this.send('validation', valuerounded, { target: { name: "beta3" } });
        }

        if (aktiv === false) {
          document.getElementsByName('beta3')[0].setSelectionRange(0, this.get('beta3').length);
        }
      },

      setSchraubenbildWerte: function setSchraubenbildWerte(te, _alphaRAD_KraftFaser, _alphaRAD_vmFaser, _betaRAD_vmFurnier, focusedButton, unfocusedButton) {

        var self = this;

        self.resetVerbindungsmittel();

        var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

        bauteile.set(te + '_alphaRAD_KraftFaser', _alphaRAD_KraftFaser);
        bauteile.set(te + '_alphaRAD_vmFaser', _alphaRAD_vmFaser);
        bauteile.set(te + '_betaRAD_vmFurnier', _betaRAD_vmFurnier);

        self.set(focusedButton, true);
        self.set(unfocusedButton, false);
      },

      setAnschnittVarianteB1: function setAnschnittVarianteB1(anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);

        var anschlussgeometrie = this.controllerFor('anschlussgeometrie');
        anschlussgeometrie.setInputsDisabled("bauteil2", "b", "beta", { "abstand1": false, "abstand2": true, "winkel1": true, "winkel2": true, "winkel3": true });

        self.resetParameterB(0, 0, 90, 90);
      },
      setAnschnittVarianteB2: function setAnschnittVarianteB2(anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);

        var anschlussgeometrie = this.controllerFor('anschlussgeometrie');
        anschlussgeometrie.setInputsDisabled("bauteil2", "b", "beta", { "abstand1": false, "abstand2": true, "winkel1": true, "winkel2": true, "winkel3": true });

        self.resetParameterB(0, 0, Number(anschlussgeometrie.get('phi')), Number(anschlussgeometrie.get('phi')));
      },
      setAnschnittVarianteB3: function setAnschnittVarianteB3(anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);

        var anschlussgeometrie = this.controllerFor('anschlussgeometrie');
        anschlussgeometrie.setInputsDisabled("bauteil2", "b", "beta", { "abstand1": false, "abstand2": true, "winkel1": false, "winkel2": true, "winkel3": true });

        self.resetParameterB(0, 0, anschlussgeometrie.get('phi'), 180 - Number(anschlussgeometrie.get('phi')));
      },
      setAnschnittVarianteB4: function setAnschnittVarianteB4(anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);

        var anschlussgeometrie = this.controllerFor('anschlussgeometrie');
        anschlussgeometrie.setInputsDisabled("bauteil2", "b", "beta", { "abstand1": false, "abstand2": false, "winkel1": false, "winkel2": false, "winkel3": true });

        self.resetParameterB(0, 0, 90, 90);
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // End Action  // hilfeTexte obeserver

    setAnschnittVarianteSelectedBauteil2: function setAnschnittVarianteSelectedBauteil2(index) {
      var self = this;

      console.log('ntAnschnittIndex: ' + index);
      self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntAnschnittIndex', index);

      self.set('asv' + self.get('letzteAnschnittVrianteBauteil2') + 'selected', false);
      self.set('asv' + index + 'selected', true);
      self.set('letzteAnschnittVrianteBauteil2', index);
    },

    resetParameterB: function resetParameterB(b1, b2, beta1, beta2) {
      var self = this;
      self.set('b1', Number(b1).toFixed(1).toString());
      self.set('b2', Number(b2).toFixed(1).toString());

      beta1 = beta1 === 180 ? 90 : beta1;
      beta2 = beta2 === 180 ? 90 : beta2;

      self.set('beta1', beta1.toFixed(1).toString());
      self.set('beta2', (180 - beta2).toFixed(1).toString());
    },

    observes_ntholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntholz", self.get('ntholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntholz', 'initTrigger'),

    observes_ntfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntfkl', 'initTrigger'),

    observes_te2vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te2vorgebohrt", "0");
      if (Number(self.get("ntholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te2vorgebohrt', 'initTrigger'),

    observes_ntbreiteHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntbreite", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntbreite', 'initTrigger'),

    observes_nthoeheHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "nthoehe", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('nthoehe', 'initTrigger'),

    observes_b1Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "b1", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('b1', 'initTrigger'),

    observes_b2Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "b2", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('b2', 'initTrigger'),

    observes_beta1Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "beta1", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('beta1', 'initTrigger'),

    observes_beta2Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "beta2", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('beta2', 'initTrigger'),

    observes_beta3Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "beta3", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('beta3', 'initTrigger'),

    // hilfeTexte display properties

    display_ntholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntholzHilfetext'));
    }).property('ntholzHilfetext'),

    display_ntfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntfklHilfetext'));
    }).property('ntfklHilfetext'),

    display_te2vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te2vorgebohrtHilfetext'));
    }).property('te2vorgebohrtHilfetext'),

    display_ntbreiteHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntbreiteHilfetext'));
    }).property('ntbreiteHilfetext'),

    display_nthoeheHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('nthoeheHilfetext'));
    }).property('nthoeheHilfetext')

  });

});