define('m03/controllers/traeger', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 10 - 999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 999.9 [cm]";
    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 10 - 999.9 [cm]";
    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 999.9 [cm]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    traeger: [{
      name: "Haupt-/Nebenträger",
      value: true
    }, {
      name: "Stütze/Nebenträger",
      value: false
    }],

    htmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    ntmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }, {
      fklind: 8,
      fkl: "C35"
    }, {
      fklind: 9,
      fkl: "C40"
    }, {
      fklind: 10,
      fkl: "C45"
    }, {
      fklind: 11,
      fkl: "C50"
    }],

    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 24,
      fkl: "GL30h"
    }, {
      fklind: 23,
      fkl: "GL32h"
    }],

    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 34,
      fkl: "GL30c"
    }, {
      fklind: 33,
      fkl: "GL32c"
    }],

    htfklarray: [],
    ntfklarray: [],

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = { target: { name: "htbreite" } };
      var hthoeheEvent = { target: { name: "hthoehe" } };
      var ntbreiteEvent = { target: { name: "ntbreite" } };
      var nthoeheEvent = { target: { name: "nthoehe" } };

      this.set('openFromFile', true);

      this.set('htholz', parseInt(values.MatKeyTimberElementHT));
      this.set('htfkl', parseInt(values.FKLKeyTimberElementHT));

      this.set('htbreite', values.TimberElementHT_b);
      this.setX3D(parseInt(values.TimberElementHT_b), htbreiteEvent);

      this.set('hthoehe', values.TimberElementHT_b);
      this.setX3D(parseInt(values.TimberElementHT_b), hthoeheEvent);

      this.set('httraeger', values.HtRiegel);

      this.set('ntholz', parseInt(values.MatKeyTimberElementNT));
      this.set('ntfkl', parseInt(values.FKLKeyTimberElementNT));

      this.set('ntbreite', values.TimberElementNT_b);
      this.setX3D(parseInt(values.TimberElementHT_b), ntbreiteEvent);

      this.set('nthoehe', values.TimberElementNT_h);
      this.setX3D(parseInt(values.TimberElementHT_b), nthoeheEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe'),

    changeHtSt: (function () {
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('istHt', this.get('httraeger'));
    }).observes('httraeger'),

    istHt: (function () {
      return this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('istHt');
    }).property('httraeger'),

    watchHtMaterial: (function () {
      console.log(this.title);
      if (this.get('htholz') === 1) {
        this.set('htfklarray', this.fkl_bsh);
        this.set('htfkl', 20);
      } else if (this.get('htholz') === 13) {
        this.set('htfklarray', this.fkl_bsh_kombi);
        this.set('htfkl', 30);
      } else {
        this.set('htfklarray', this.fkl_vh);
        this.set('htfkl', 5);
      }
    }).observes('htholz'),

    watchNtMaterial: (function () {
      if (this.get('ntholz') === 1) {
        this.set('ntfklarray', this.fkl_bsh);
        this.set('ntfkl', 20);
      } else if (this.get('ntholz') === 13) {
        this.set('ntfklarray', this.fkl_bsh_kombi);
        this.set('ntfkl', 30);
      } else {
        this.set('ntfklarray', this.fkl_vh);
        this.set('ntfkl', 5);
      }
    }).observes('ntholz'),

    init: function init() {
      this._super();
      this.set('htfklarray', this.fkl_vh);
      this.set('ntfklarray', this.fkl_vh);

      this.set('hauptnebentraeger', this.get('i18n').t('bemessungslast').toString());
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      console.log(event.target);

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('x', value);
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', true);

            if (this.errors.hthoehe.length !== 0) {
              if (value * 1.5 > 10) {
                bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('y', value * 1.5);
              } else {
                bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('y', 10);
              }
            }

            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('y', value);
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', true);

            if (this.errors.htbreite.length !== 0) {
              bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('x', value * 0.75);
            }

            self.setZPosSchraube();
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
        case 'ntbreite':
          if (this.errors.ntbreite.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value);
            if (application.get('model').x3ddefault.objectAt(0).get('istHt')) {
              bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('z', value * 4);
            }
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', true);

            if (this.errors.nthoehe.length !== 0) {
              bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('x', value * 1.5);
            }

            self.setZPosSchraube();
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', false);
          }
          break;
        case 'nthoehe':
          if (this.errors.nthoehe.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('x', value);
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', true);

            if (!application.get('model').x3ddefault.objectAt(0).get('istHt')) {
              bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('z', value * 2);
            }

            self.setSchraubenlaenge();

            if (this.errors.ntbreite.length !== 0) {
              bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value * 0.75);
            }
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', false);
          }
          break;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
    },

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var traeger = this.controllerFor('application').get('model').traeger.objectAt(0);

      traeger.set('MatKeyTimberElement', this.get('ntholz'));
      traeger.set('FKLKeyTimberElement', this.get('ntfkl'));
    }).observes('ntholz', 'ntfkl'),

    setSchraubenlaenge: function setSchraubenlaenge() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var hWinkel = x3d.get('hWinkel') * Math.PI / 180;
      var vWinkel = x3d.get('vWinkel') * Math.PI / 180;

      var htHoehe = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y');
      var ntHoehe = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('x');

      var breiteHT = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x');

      var values = [htHoehe * 2 / 3, ntHoehe * 2 / 3, breiteHT * 2 / 3];

      values.sort(function (a, b) {
        return a - b;
      });

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {

          var schraubenLaengeAlt = bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).get('length');

          var schraubenLaenge = values[0] / Math.sin(x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).get('z'));

          var y = -(Math.sin(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge) - Math.abs(Math.tan(vWinkel) * (Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2));

          var laengeAltRelativ = Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaengeAlt / 2;
          var laengeNeuRelativ = Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2;

          var zAlt = bauteile.findBy('id', item.id).get('translations').objectAt(0).get('z');

          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('x', -(Math.sin(hWinkel) * (Math.cos(item.get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2)));
          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('y', y);

          if (!application.get('model').x3ddefault.objectAt(0).get('schraubenGekreuzt') && bauteile.findBy('id', item.id).get('translations').objectAt(0).get('x') > 0) {
            bauteile.findBy('id', item.id).get('translations').objectAt(0).set('z', Math.abs(-zAlt - Math.cos(hWinkel) * laengeAltRelativ + Math.cos(hWinkel) * laengeNeuRelativ));
          } else {
            bauteile.findBy('id', item.id).get('translations').objectAt(0).set('z', zAlt - Math.cos(hWinkel) * laengeAltRelativ + Math.cos(hWinkel) * laengeNeuRelativ);
          }
          bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).set('length', schraubenLaenge);
          bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
        }
      });
    },

    setZPosSchraube: function setZPosSchraube() {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var ntBreite = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z');
      var htBreite = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y');

      application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.id.substring(0, 8) === 'schraube') {

          var hWinkel = application.get('model').x3ddefault.objectAt(0).get('hWinkel') * Math.PI / 180;
          var itemTranslations = item.get('translations').objectAt(0);
          var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length') / 2;

          if (x3d.get('istHt') || Number(htBreite) > ntBreite) {
            if (itemTranslations.get('x') < 0) {
              itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge - ntBreite / 5.5);
            } else {
              if (x3d.get('schraubenGekreuzt')) {
                itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge + ntBreite / 5.5);
              } else {
                itemTranslations.set('z', Math.abs(Math.cos(hWinkel) * itemLaenge) + ntBreite / 5.5);
              }
            }
          } else {
            if (itemTranslations.get('x') < 0) {
              itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge - htBreite / 5.5);
            } else {
              if (x3d.get('schraubenGekreuzt')) {
                itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge + htBreite / 5.5);
              } else {
                itemTranslations.set('z', Math.abs(Math.cos(hWinkel) * itemLaenge) + htBreite / 5.5);
              }
            }
          }
        }
      });
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('traegerInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var auflager = self.controllerFor('auflager');
            auflager.set('validations.abreite.numericality.greaterThanOrEqualTo', parseFloat(self.get('htbreite')));
            auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('htbreite') + " - 240 [mm]");

            var traeger = self.controllerFor('application').get('model').traeger.objectAt(0);

            traeger.set('TimberElement_b', parseFloat(self.get('htbreite')).toFixed(1));
            traeger.set('TimberElement_h', parseFloat(self.get('hthoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('traegerInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation');
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation');
        }
      },

      ntbreiteIsSelected: function ntbreiteIsSelected() {
        var ntbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ntbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntbreiteAktiv', !ntbreiteaktiv);

        if (this.get('ntbreite') !== "") {
          var ntbreitework = parseFloat(this.get('ntbreite').replace(",", "."));
          var ntbreiterounded = ntbreitework.toFixed(1);
          this.set('ntbreite', ntbreiterounded);
          this.send('validation');
        }
      },

      nthoeheIsSelected: function nthoeheIsSelected() {
        var nthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('nthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('nthoeheAktiv', !nthoeheaktiv);

        if (this.get('nthoehe') !== "") {
          var nthoehework = parseFloat(this.get('nthoehe').replace(",", "."));
          var nthoeherounded = nthoehework.toFixed(1);
          this.set('nthoehe', nthoeherounded);
          this.send('validation');
        }
      }

    }
  });

});