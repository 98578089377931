define('m03/components/schraube-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schrauben = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    klammerBezeichnung: "klammer",

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    // erstesVerbindungsmittel: function(){
    //   var self = this;
    //   var x3d = this.get('model').objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //   var ersteschraube = "schraubeStandard1"
    //   var ersteschraubeGefunden = false
    //
    //   if (x3d.get('ergebnisGeladen')){
    //     bauteile.forEach(function(item){
    //       if (ersteschraubeGefunden === false && item.get('typ') === x3d.get('aktuellerSchraubenTyp')){
    //         console.log("item.id: "+item.id);
    //         ersteschraubeGefunden = true;
    //         ersteschraube = item.id.toString();
    //       }
    //     });
    //   }
    //
    //   return ersteschraube;
    //
    // }.property('model.firstObject.schraubenlisteAktualisiert'),

    // vmGruppeEnum
    //  Stabduebel = 0
    //  Passbolzen = 1
    //  Bolzen = 2
    //  Gewindestange = 3
    //  Nagel = 4
    //  Schraube = 5
    //  Klammer = 6

    // schrauben: function(){
    //   var self = this;
    //   var x3d = this.get('model').objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //   var value = false;
    //
    //   if (x3d.get('ergebnisGeladen')){
    //     bauteile.forEach(function(item){
    //       if(item.get('typ') === x3d.get('aktuellerSchraubenTyp') && (item.get('screwProperties').objectAt(0).get('vmTypID') === 5 || item.get('screwProperties').objectAt(0).get('vmTypID') === 1 || item.get('screwProperties').objectAt(0).get('vmTypID') === 2 || item.get('screwProperties').objectAt(0).get('vmTypID') === 4)){
    //         value = true;
    //         return true;
    //       }
    //     });
    //   }else{
    //     value = true;
    //   }
    //
    //   console.log("schrauben: "+value);
    //
    //   return value
    // }.property('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen', 'model.firstObject.schraubenlisteAktualisiert'),
    //
    // klammern: function(){
    //   var self = this;
    //   var x3d = this.get('model').objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //   var value = false;
    //
    //   if (x3d.get('ergebnisGeladen')){
    //     bauteile.forEach(function(item){
    //       if(item.get('typ') === x3d.get('aktuellerSchraubenTyp') && item.get('screwProperties').objectAt(0).get('vmTypID') === 6 ){
    //         value = true;
    //         return true;
    //       }
    //     });
    //   }else{
    //     value = false;
    //   }
    //
    //   return value
    // }.property('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen', 'model.firstObject.schraubenlisteAktualisiert'),
    //
    // stabduebel: function(){
    //   var self = this;
    //   var x3d = this.get('model').objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //   var value = false;
    //
    //   if (x3d.get('ergebnisGeladen')){
    //     bauteile.forEach(function(item){
    //       if(item.get('typ') === x3d.get('aktuellerSchraubenTyp') && item.get('screwProperties').objectAt(0).get('vmTypID') === 0 ){
    //         value = true;
    //         return true;
    //       }
    //     });
    //   }else{
    //     value = false;
    //   }
    //
    //   console.log("stabduebel: "+value);
    //
    //   return value
    // }.property('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen', 'model.firstObject.schraubenlisteAktualisiert'),

    schrauben: true,
    klammern: false,
    stabduebel: false,

    // stabduebelListe: function(){
    //   var self = this;
    //   var x3d = this.get('model').objectAt(0);
    //   var bauteile = x3d.get('bauteile');
    //   var count = 0;
    //   var schrListe = Ember.Set.create();
    //
    //   if (x3d.get('ergebnisGeladen') === true){
    //     bauteile.forEach(function(item){
    //       if (item.get('typ') === x3d.get('aktuellerSchraubenTyp') && item.get('screwProperties').objectAt(0).get('vmTypID') === 0 ){
    //         if(count === 0){
    //           x3d.set('erstesVerbindungsmittel', item.id);
    //         }
    //         count = count + 1;
    //         schrListe.add(item);
    //       }
    //     });
    //   }
    //
    //   if(x3d.get('ergebnisGeladen')){
    //     this.setAbstaende(schrListe);
    //   }
    //
    //   console.log(" ");
    //   console.log("##### Stabdübelliste #####");
    //   console.log(schrListe.toArray());
    //
    //   return schrListe.toArray();
    // }.property('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen', 'model.firstObject.schraubenlisteAktualisiert'),

    schraubenListe: (function () {
      var self = this;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var count = 0;
      var schrListe = Ember['default'].Set.create();

      if (x3d.get('ergebnisGeladen')) {

        bauteile.forEach(function (item) {
          if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
            // if(Number(item.get('screwProperties').objectAt(0).get('vmTypID')) === 0){
            //   self.set('schrauben', false);
            //   self.set('stabduebel', true);
            // }else if((item.get('screwProperties').objectAt(0).get('vmTypID') === 5 || item.get('screwProperties').objectAt(0).get('vmTypID') === 1 || item.get('screwProperties').objectAt(0).get('vmTypID') === 2 || item.get('screwProperties').objectAt(0).get('vmTypID') === 4)){
            //   self.set('schrauben', true);
            //   self.set('stabduebel', false);
            // }

            if (count === 0) {
              x3d.set('erstesVerbindungsmittel', item.id);
            }
            count = count + 1;
            schrListe.add(item);
          }
        });
      } else {
        self.set('schrauben', true);
        self.set('stabduebel', false);

        x3d.set("erstesVerbindungsmittel", "schraubeStandard1");
        var item = bauteile.findBy('id', 'schraubeStandard1');
        schrListe.add(item);
        if (x3d.get('zweiSchaerflaechen') && !x3d.get('zweiSchnittig')) {
          var item = bauteile.findBy('id', 'schraubeStandard2');
          schrListe.add(item);
        }
      }

      if (this.get('model').objectAt(0).get('ergebnisGeladen')) {
        this.setAbstaende(schrListe);
      }

      return schrListe.toArray();
    }).property('model.firstObject.transformHelper', 'model.firstObject.ergebnisGeladen', 'model.firstObject.schraubenlisteAktualisiert'),

    setAbstaende: function setAbstaende(schrliste) {
      var x3d = this.get('model').objectAt(0);

      var xMittelbauteil = x3d.get('xMittelbauteil');
      var xSeitenbauteil = x3d.get('xSeitenbauteil');

      var yMittelbauteil = x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var ySeitenbauteil = x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');

      var xPosListe = [];
      var yPosListe = [];

      var xAbstand1 = 0,
          xAbstand2 = 0,
          yAbstand1 = 0,
          yAbstand1 = 0,
          yAbstand2 = 0,
          schraubenAbstandX = 0,
          schraubenAbstandY = 0;

      schrliste.forEach(function (item) {

        xPosListe.push(item.get('translations').objectAt(0).get('x'));
        yPosListe.push(item.get('translations').objectAt(0).get('y'));
      });

      xPosListe.sort(function (a, b) {
        return b - a;
      });
      yPosListe.sort(function (a, b) {
        return b - a;
      });

      xAbstand1 = Math.abs(xMittelbauteil) - xPosListe[0];
      xAbstand2 = Math.abs(xSeitenbauteil) - xPosListe[length - 1];

      yAbstand1 = Math.abs(yMittelbauteil) - yPosListe[0];
      yAbstand2 = Math.abs(ySeitenbauteil) - yPosListe[length - 1];

      x3d.set('xAbstand1', Math.abs(xAbstand1));
      x3d.set('xAbstand2', Math.abs(xAbstand2));

      // schraubenAbstandX = Math.abs(xPosListe[0]-xPosListe[1]);
      //
      // if(yPosListe.length >2){
      //   schraubenAbstandY = yPosListe[0]-yPosListe[1];
      // }else{
      //   schraubenAbstandY = yPosListe[0]-yPosListe[3];
      // }

      x3d.set('schraubenAbstandX', schraubenAbstandX);
      x3d.set('schraubenAbstandY', schraubenAbstandY);
    },

    schraubenabstaende1: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand1') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand1', 'model.firstObject.schraubenlisteAktualisiert'),

    schraubenabstaende2: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand2') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand2', 'model.firstObject.schraubenlisteAktualisiert'),

    gekreuzteSchrauben: (function () {
      if (this.get('model').objectAt(0).get('schraubenGekreuzt')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenGekreuzt'),

    abstaendeAnzeigen: (function () {
      if (this.get('model').objectAt(0).get('abstaendeAnzeigen') && this.get('model').objectAt(0).get('ergebnisGeladen')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.abstaendeAnzeigen', 'model.firstObject.ergebnisGeladen')

  });

  // uscheibeAnzeigen: function () {
  //   var x3d = this.get('model').objectAt(0);

  //   return x3d.get("uScheibe");
  // }.property('model.firstObject.uScheibe'),

  exports['default'] = Schrauben;

});